import React from "react";
import ContactComponent from "../components/contactuscomponent/contactComponent";

const ContactUS = () => {
  return (
    <React.Fragment>
      <ContactComponent />
    </React.Fragment>
  );
};

export default ContactUS;
