import EventsAndActivitiesComponent from "../components/eventsactivitiescom/eventsAndActivitiesCom";

const Events = () => {
  return (
    <div>
      <EventsAndActivitiesComponent />
    </div>
  );
};

export default Events;
