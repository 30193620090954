import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import imgSchoolPrimary from "../../images/school/or/schoolHomeImage.png";
import imgBanner from "../../images/banner/ssabanner-new.png";

const theme = createTheme();
const villagePopulation = 425;
const studentCount = 49;
const establishedYear = 2012;

const AboutForIndex = () => {
  function setImage() {
    if (window.devicePixelRatio === 1) {
      setImageToShow(imgBanner);
      setImageHeight(240);
    }
  }

  useEffect(() => {
    setImage();
  });

  const [imageToShow, setImageToShow] = useState(imgSchoolPrimary);
  const [imageHeight, setImageHeight] = useState(240);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        <Card>
          <CardMedia
            component="img"
            height={imageHeight}
            image={imageToShow}
            alt="Primary School Kathwa"
            objectfit="contain"
            layout="fill"
          />

          <CardContent>
            <Typography gutterBottom variant="h4" component="div">
              Primary School Kathwa
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Primary school Kathwa is located in the village of Kathwa in the
              district of Shahjahanpur. This school was established in{" "}
              {establishedYear} by the government.
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Kathwa is a small village and has a population of{" "}
              {villagePopulation}. Currently, the student count of the school is{" "}
              {studentCount}.
            </Typography>
          </CardContent>
        </Card>
      </main>
    </ThemeProvider>
  );
};

export default AboutForIndex;
