import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";

const facilitiesList = [
  {
    id: 1,
    title: "Smart Classrooms",
    description:
      "We use technology as a tool to make learning easy. We are using interactive teaching methods, LCD projectors, and sound systems to teach a little complex topic. All classrooms are smart-classes and have LCD projectors (LED TVs) with a sound system.",
  },
  {
    id: 2,
    title: "A Library",
    description:
      "We have a well-maintained library at our school. This library has a rich collection of children-friendly books.",
  },
  {
    id: 3,
    title: "Ambience",
    description:
      "We used colourful paintings, slogans, and stories of our heroes to make school time enjoyable and colourful. Sometimes we use the same stories and paintings to motivate the students to become a good human.",
  },
  {
    id: 4,
    title: "The Furniture",
    description:
      "We have well-furnished furniture for all the students according to their age.",
  },
  {
    id: 5,
    title: "Drinking Water",
    description: "We have a fresh and pure water facility at our school.",
  },
  {
    id: 6,
    title: "Toilet",
    description:
      "We have a conventional toilet facility at our school. Additionally, there is one dedicated toilet for children with special needs(handicapped).",
  },
  {
    id: 7,
    title: "Kitchen",
    description:
      "We have a well-maintained, clean and organized kitchen at our school.",
  },
  {
    id: 8,
    title: "Mid-day Meal",
    description:
      "We have a skilled cook at our school. We serve a healthy mid-day meal according to the menu.",
  },
];

const theme = createTheme();

const FacilitiesForIndexComponent = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 4,
            pb: 2,
          }}
        >
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Facilities
            </Typography>
          </Container>
        </Box>
        <div>
        <Container maxWidth="xl">
          
        <Grid container spacing={1}>
        {facilitiesList.map((facility) => (
          <Grid item key={facility.id} xs={12} sm={6} md={6}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography gutterBottom>{facility.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography align="left" variant="body2" color="text.secondary">{facility.description}</Typography>
              </AccordionDetails>
            </Accordion>
            </Grid>
          ))}
        </Grid>
        <div style={{ margin: "2px" }}>
            <Stack direction="row" spacing={4}>
              <Button variant="contained" component={Link} to={"/facilities"}>
                Click here to checkout all facilities
              </Button>
            </Stack>
          </div>
        </Container>
        </div>
      </main>
    </ThemeProvider>
  );
};

export default FacilitiesForIndexComponent;
