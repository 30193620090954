import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import awardOne from "../../images/awards//or/nipunbalikapuruskar2022.png";
import awardTwo from "../../images/awards/or/prerakbalakpuruskar2021.png";
import awardFive from "../../images/awards/or/utkristvidyalayapuruskar2021.png";
import awardThree from "../../images/awards/op/awards_school.png";

const awardsList = [
  {
    id: 1,
    img: awardOne,
    name: "Nipun (Prerak) Balika Puraskar 2022",
    about: "Prerak Balika Puraskar at block level in 2022.",
    featured: true,
  },
  {
    id: 2,
    img: awardTwo,
    name: "Prerak Balak Puraskar 2021",
    about: "Prerak Balak Puraskar at block level in 2021.",
  },
  {
    id: 4,
    img: awardFive,
    name: "Utkrist Vidyalaya Puraskar 2021",
    about: "Utkrist Vidyalaya Puraskar at block level in 2021.",
  },
  {
    id: 3,
    img: awardThree,
    name: "Vocal For Local 2021",
    about: "First position in the district level event of 'Vocal For Local' in 2021.",
  }
];

const theme = createTheme();

const AwardsComponent = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <main>
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 4,
            pb: 2,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Awards
            </Typography>
            <Typography
              variant="body"
              align="center"
              color="text.secondary"
              paragraph
            >
              Primary school Kathwa has won four awards.
            </Typography>
          </Container>
        </Box>
        <Container maxWidth="xl">
          {/* End hero unit */}
          <Grid container spacing={2}>
            {awardsList.map((award) => (
              <Grid item key={award.id} xs={12} sm={6} md={6}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={award.img}
                    alt={award.name}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {award.name}
                    </Typography>
                    <Typography>{award.about}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </ThemeProvider>
  );
};

export default AwardsComponent;
