import FacilitiesComponent from "../components/facilitiescom/facilitiesCom";

const Facilities = () => {
  return (
    <div>
      <FacilitiesComponent />
    </div>
  );
};

export default Facilities;
