import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import activityOne from "../../images/activities/op/activity-1.png";
import activityTwo from "../../images/activities/op/activity-7.png";
import activityThree from "../../images/activities/op/activity-11.png";
import { Link } from "react-router-dom";


const activities = [
  {
    id: 1,
    img: activityOne,
    title: "activity",
    descriptionshort: "Describe the activity",
    featured: true,
  },
  {
    id: 2,
    img: activityTwo,
    title: "activity",
    descriptionshort: "Describe the activity",
  },
  {
    id: 3,
    img: activityThree,
    title: "activity",
    descriptionshort: "Describe the activity",
  }
];

const theme = createTheme();

const ActivitiesForIndexComponent = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 4,
            pb: 2,
          }}
        >
          <Container maxWidth="sm">          
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Activities
            </Typography>
            <Typography
              variant="body"
              align="center"
              color="text.secondary"
              paragraph
            >
              We organise activites for the students and as well as for the villagers.
            </Typography>
           
          </Container>
        </Box>
        <Container maxWidth="xl">
          {/* End hero unit */}
          <Grid container spacing={2}>
            {activities.map((activity) => (
              <Grid item key={activity.id} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={activity.img}
                    alt={activity.title}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    {/* <Typography gutterBottom variant="h5" component="h2">
                      {activity.title}
                    </Typography>
                    <Typography>{activity.descriptionshort}</Typography> */}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <div style={{ margin: "2px" }}>
            <Stack direction="row" spacing={4}>
              <Button variant="contained" component={Link} to={"/activities"}>
                Click here to checkout all activities
              </Button>
            </Stack>
          </div>
        </Container>
      </main>
    </ThemeProvider>
  );
};

export default ActivitiesForIndexComponent;
