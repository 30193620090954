import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme();

const ContactComponent = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <main>
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 4,
            pb: 1,
          }}
        >
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Address
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color="text.primary"
            >
              Primary School Kathwa
            </Typography>
            <Typography
              variant="body"
              align="center"
              color="text.secondary"
              paragraph
            >
              Village: Kathwa, Post: Anawa, Block: Sindhauli, Disctrict: Shahjahanpur, Uttar Pradesh, India 242401.
            </Typography>
          </Container>
        </Box>
        <Container sx={{ py: 1 }} maxWidth="sm">
          {/* End hero unit */}
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3523.581152342281!2d80.10659191506797!3d27.97611098268141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x19d1b53633e1855!2zMjfCsDU4JzM0LjAiTiA4MMKwMDYnMzEuNiJF!5e0!3m2!1sen!2sin!4v1669745570543!5m2!1sen!2sin"
                    width="320"
                    height="460"
                    style={{ border: "1" }}
                    allowFullScreen={""}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Schoollocationonmap"
                  ></iframe>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </main>
    </ThemeProvider>
  );
};

export default ContactComponent;
