import "./GkGameComponent.css";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";

import React, { useState } from "react";
const theme = createTheme();
export default function GkGameComponent() {
  const questions = [
    {
      questionText: "How many days are there in a year?",
      answerOptions: [
        { answerText: "364", isCorrect: false },
        { answerText: "360", isCorrect: false },
        { answerText: "365", isCorrect: true },
        { answerText: "260", isCorrect: false },
      ],
    },
    {
      questionText: "How many minutes are there in an hour?",
      answerOptions: [
        { answerText: "61 minutes", isCorrect: false },
        { answerText: "60 minutes", isCorrect: true },
        { answerText: "65 minutes", isCorrect: false },
        { answerText: "62 minutes", isCorrect: false },
      ],
    },
    {
      questionText: "How many seconds are there in a minute?",
      answerOptions: [
        { answerText: "61 seconds", isCorrect: false },
        { answerText: "65 seconds", isCorrect: false },
        { answerText: "59 seconds", isCorrect: false },
        { answerText: "60 seconds", isCorrect: true },
      ],
    },
    {
      questionText: "How many seconds make one hour?",
      answerOptions: [
        { answerText: "3600 seconds", isCorrect: true },
        { answerText: "6300 seconds", isCorrect: false },
        { answerText: "1000 seconds", isCorrect: false },
        { answerText: "60 seconds", isCorrect: false },
      ],
    },
    {
      questionText: "How many consonants are there in the English alphabet?",
      answerOptions: [
        { answerText: "20 Consonants", isCorrect: false },
        { answerText: "27 Consonants", isCorrect: false },
        { answerText: "23 Consonants", isCorrect: false },
        { answerText: "21 Consonants", isCorrect: true },
      ],
    },
    {
      questionText: "How many days are there in a week?",
      answerOptions: [
        { answerText: "6 days", isCorrect: false },
        { answerText: "7 days", isCorrect: true },
        { answerText: "8 days", isCorrect: false },
        { answerText: "5 days", isCorrect: false },
      ],
    },
    {
      questionText: "In which direction does the sunrise?",
      answerOptions: [
        { answerText: "South", isCorrect: false },
        { answerText: "West", isCorrect: false },
        { answerText: "North", isCorrect: false },
        { answerText: "East", isCorrect: true },
      ],
    },
    {
      questionText: "Which animal is known as the 'Ship of the Desert'?",
      answerOptions: [
        { answerText: "Lion", isCorrect: false },
        { answerText: "Giraffe", isCorrect: false },
        { answerText: "Camel", isCorrect: true },
        { answerText: "Tiger", isCorrect: false },
      ],
    },
    {
      questionText: "How many hours are there in a day?",
      answerOptions: [
        { answerText: "24 hours", isCorrect: true },
        { answerText: "21 hours", isCorrect: false },
        { answerText: "23 hours", isCorrect: false },
        { answerText: "20 hours", isCorrect: false },
      ],
    },
    {
      questionText: "Name the National bird of India?",
      answerOptions: [
        { answerText: "The Pigeon", isCorrect: false },
        { answerText: "The Sparrow", isCorrect: false },
        { answerText: "The Parrot", isCorrect: false },
        { answerText: "The Peacock", isCorrect: true },
      ],
    },
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);

  const handleAnswerOptionClick = (isCorrect) => {
    if (isCorrect) {
      setScore(score + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        {showScore ? (
          <React.Fragment>
          <div className="score-section">
            You scored {score} out of {questions.length}
          </div>
          <Button variant="contained"  href="/gkgame">
          New Game
        </Button>
        </React.Fragment>
        ) : (
          <>
            <div className="question-section">
              <div className="question-count">
                <span>Question {currentQuestion + 1}</span>/{questions.length}
              </div>
              <div className="question-text">
                {questions[currentQuestion].questionText}
              </div>
            </div>
            <div className="answer-section">
              {questions[currentQuestion].answerOptions.map((answerOption) => (
                <button
                  onClick={() =>
                    handleAnswerOptionClick(answerOption.isCorrect)
                  }
                >
                  {answerOption.answerText}
                </button>
              ))}
            </div>
          </>
        )}
      </main>
    </ThemeProvider>
  );
}
