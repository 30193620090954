import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import imgbg from "./images/bg/body-bg.jpg";
import Teachers from "./pages/teachers";
import AboutUS from "./pages/aboutus";
import ContactUS from "./pages/contactus";
import WebappHeader from "./components/AppHeaderFooter/WebappHeader";
import WebappFooter from "./components/AppHeaderFooter/WebappFooter";
import Events from "./pages/events";
import SchoolIndex from "./pages/schoolindex";
import Facilities from "./pages/facilities";
import ActivitiesComponent from "./components/activitiescom/activitiesCom";
import AwardsComponent from "./components/awardComponent/awardsComponent";
import ScrollToTop from "react-scroll-to-top";
import GkGameTest from "./pages/generalknowledgetest";

function App() {
  return (
    <div className="App" style={{ backgroundImage: `url(${imgbg})` }}>
      <BrowserRouter>
        <WebappHeader />
        <ScrollToTop smooth color="#002884" />
        <Routes>
          <Route path="/" element={<SchoolIndex />}></Route>
          <Route path="/home" element={<SchoolIndex />}></Route>
          <Route path="/awards" element={<AwardsComponent />}></Route>
          <Route path="/teachers" element={<Teachers />}></Route>
          <Route path="/facilities" element={<Facilities />}></Route>
          <Route path="/events" element={<Events />}></Route>
          <Route path="/activities" element={<ActivitiesComponent />}></Route>
          <Route path="/aboutus" element={<AboutUS />}></Route>
          <Route path="/contactus" element={<ContactUS />}></Route>
          <Route path="/gkgame" element={<GkGameTest />}></Route>
        </Routes>
      </BrowserRouter>
      <WebappFooter />
    </div>
  );
}

export default App;
