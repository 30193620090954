import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import eventOne from "../../images/events/op/events-1.png";
import eventTwo from "../../images/events/op/events-2.png";
import eventThree from "../../images/events/op/events-3.png";
import { Link } from "react-router-dom";


const events = [
  {
    id: 1,
    img: eventOne,
    title: "Event",
    descriptionshort: "Describe the event",
    featured: true,
  },
  {
    id: 2,
    img: eventTwo,
    title: "Event",
    descriptionshort: "Describe the event",
  },
  {
    id: 3,
    img: eventThree,
    title: "Event",
    descriptionshort: "Describe the event",
  }
];

const theme = createTheme();

const EventsForIndexComponent = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 4,
            pb: 2,
          }}
        >
          <Container maxWidth="sm">          
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Events
            </Typography>
            <Typography
              variant="body"
              align="center"
              color="text.secondary"
              paragraph
            >
              We organise regular sports and cultural events at our school.
            </Typography>
           
          </Container>
        </Box>
        <Container maxWidth="xl">
          {/* End hero unit */}
          <Grid container spacing={2}>
            {events.map((event) => (
              <Grid item key={event.id} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={event.img}
                    alt={event.title}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    {/* <Typography gutterBottom variant="h5" component="h2">
                      {event.title}
                    </Typography>
                    <Typography>{event.descriptionshort}</Typography> */}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <div style={{ margin: "2px" }}>
            <Stack direction="row" spacing={4}>
              <Button variant="contained" component={Link} to={"/events"}>
                Click here to checkout all events
              </Button>
            </Stack>
          </div>
        </Container>
      </main>
    </ThemeProvider>
  );
};

export default EventsForIndexComponent;
