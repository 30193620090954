import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import teacherOne from "../../images/teachers/op/teacher-1.png";
import teacherTwo from "../../images/teachers/op/teacher-2.png";

const teachersList = [
  {
    id: 1,
    img: teacherOne,
    name: "SHARAD KUMAR",
    about: "Qualification: M.SC, B.Ed, D.BTC, TET",
    featured: true,
  },
  {
    id: 2,
    img: teacherTwo,
    name: "CHHAYA AWASTHI",
    about: "Qualification: M.A, B.Ed, TET",
  },
];

const theme = createTheme();

const TeacherComponent = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <main>
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 4,
            pb: 2,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Teachers
            </Typography>
            <Typography
              variant="body"
              align="center"
              color="text.secondary"
              paragraph
            >
              Primary school Kathwa has two teachers and 49 students.
            </Typography>
          </Container>
        </Box>
        <Container maxWidth="lg">
          {/* End hero unit */}
          <Grid container spacing={2}>
            {teachersList.map((teacher) => (
              <Grid item key={teacher.id} xs={12} sm={6} md={6}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={teacher.img}
                    alt={teacher.name}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {teacher.name}
                    </Typography>
                    <Typography>{teacher.about}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </ThemeProvider>
  );
};

export default TeacherComponent;
