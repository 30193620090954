import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import eventOne from "../../images/events/op/events-1.png";
import eventTwo from "../../images/events/op/events-2.png";
import eventThree from "../../images/events/op/events-3.png";
import eventFour from "../../images/events/op/events-4.png";
import eventFive from "../../images/events/op/events-5.png";
import eventSix from "../../images/events/op/events-6.png";
import eventSeven from "../../images/events/op/events-7.png";
import eventEight from "../../images/events/op/events-8.png";
import eventNine from "../../images/events/op/events-9.png";
import eventTen from "../../images/events/op/events-10.png";
import eventEleven from "../../images/events/op/events-11.png";
import eventThirteen from "../../images/events/op/events-13.png";
import eventFourteen from "../../images/events/op/events-14.png";
import eventFifteen from "../../images/events/op/events-15.png";
import eventSixteen from "../../images/events/op/events-16.png";
import eventSeventeen from "../../images/events/op/events-17.png";

const events = [
  {
    id: 1,
    img: eventOne,
    title: "Event",
    descriptionshort: "Describe the event",
    featured: true,
  },
  {
    id: 2,
    img: eventTwo,
    title: "Event",
    descriptionshort: "Describe the event",
  },
  {
    id: 3,
    img: eventThree,
    title: "Event",
    descriptionshort: "Describe the event",
  },
  {
    id: 4,
    img: eventFour,
    title: "Event",
    descriptionshort: "Describe the event",
  },
  {
    id: 5,
    img: eventFive,
    title: "Event",
    descriptionshort: "Describe the event",
  },
  {
    id: 6,
    img: eventSix,
    title: "Event",
    descriptionshort: "Describe the event",
  },
  {
    id: 7,
    img: eventSeven,
    title: "Event",
    descriptionshort: "Describe the event",
  },
  {
    id: 8,
    img: eventEight,
    title: "Event",
    descriptionshort: "Describe the event",
  },
  {
    id: 9,
    img: eventNine,
    title: "Event",
    descriptionshort: "Describe the event",
  },
  {
    id: 10,
    img: eventTen,
    title: "Event",
    descriptionshort: "Describe the event",
  },
  {
    id: 11,
    img: eventEleven,
    title: "Event",
    descriptionshort: "Describe the event",
  },
  {
    id: 13,
    img: eventThirteen,
    title: "Event",
    descriptionshort: "Describe the event",
  },
  {
    id: 14,
    img: eventFourteen,
    title: "Event",
    descriptionshort: "Describe the event",
  },
  {
    id: 15,
    img: eventFifteen,
    title: "Event",
    descriptionshort: "Describe the event",
  },
  {
    id: 16,
    img: eventSixteen,
    title: "Event",
    descriptionshort: "Describe the event",
  },
  {
    id: 17,
    img: eventSeventeen,
    title: "Event",
    descriptionshort: "Describe the event",
  },
  
];

const theme = createTheme();

const EventsAndActivitiesComponent = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 4,
            pb: 2,
          }}
        >
          <Container maxWidth="sm">          
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Events
            </Typography>
            <Typography
              variant="body"
              align="center"
              color="text.secondary"
              paragraph
            >
              We organise regular sports and cultural events at our school.
            </Typography>
           
          </Container>
        </Box>
        <Container maxWidth="xl">
          {/* End hero unit */}
          <Grid container spacing={2}>
            {events.map((event) => (
              <Grid item key={event.id} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={event.img}
                    alt={event.title}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    {/* <Typography gutterBottom variant="h5" component="h2">
                      {event.title}
                    </Typography>
                    <Typography>{event.descriptionshort}</Typography> */}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </ThemeProvider>
  );
};

export default EventsAndActivitiesComponent;
