import React from "react";

import AboutComponent from "../components/aboutcomponent/aboutComponent";
// import AboutForIndex from "../components/aboutcomponent/aboutForIndex";

const AboutUS = () => {
  return (
    <React.Fragment>
      {/* <AboutForIndex /> */}
      <AboutComponent />
    </React.Fragment>
  );
};

export default AboutUS;
