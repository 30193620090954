import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AboutForIndex from "../components/aboutcomponent/aboutForIndex";
import ContactComponent from "../components/contactuscomponent/contactComponent";
import TeacherComponent from "../components/teachersComponent/teacherComponent";
import EventsForIndexComponent from "../components/eventsactivitiescom/eventsForIndex";
import AwardsForIndexComponent from "../components/awardComponent/awardsForIndex";
import ActivitiesForIndexComponent from "../components/activitiescom/activitiesForIndex";
import FacilitiesForIndexComponent from "../components/facilitiescom/facilitiesForIndex";

const theme = createTheme();

const SchoolIndex = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        <AboutForIndex />
        <AwardsForIndexComponent/>
        <EventsForIndexComponent/>
        <ActivitiesForIndexComponent/>
        <FacilitiesForIndexComponent/>
        <TeacherComponent />
        <ContactComponent />
      </main>
    </ThemeProvider>
  );
};

export default SchoolIndex;
