import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import activityOne from "../../images/activities/op/activity-1.png";
import activityTwo from "../../images/activities/op/activity-2.png";
import activityThree from "../../images/activities/op/activity-3.png";
import activityFour from "../../images/activities/op/activity-4.png";
import activityFive from "../../images/activities/op/activity-5.png";
import activitySix from "../../images/activities/op/activity-6.png";
import activitySeven from "../../images/activities/op/activity-7.png";
import activityEight from "../../images/activities/op/activity-8.png";
import activityNine from "../../images/activities/op/activity-9.png";
import activityTen from "../../images/activities/op/activity-10.png";
import activityEleven from "../../images/activities/op/activity-11.png";
import activityTwelve from "../../images/activities/op/activity-12.png";
import activityThirteen from "../../images/activities/op/activity-13.png";
import activityFourteen from "../../images/activities/op/activity-14.png";
import activityFifteen from "../../images/activities/op/activity-15.png";
import activitySixteen from "../../images/activities/op/activity-16.png";


const activities = [
  {
    id: 1,
    img: activityOne,
    title: "activity",
    descriptionshort: "Describe the activity",
    featured: true,
  },
  {
    id: 2,
    img: activityTwo,
    title: "activity",
    descriptionshort: "Describe the activity",
  },
  {
    id: 3,
    img: activityThree,
    title: "activity",
    descriptionshort: "Describe the activity",
  },
  {
    id: 4,
    img: activityFour,
    title: "activity",
    descriptionshort: "Describe the activity",
  },
  {
    id: 5,
    img: activityFive,
    title: "activity",
    descriptionshort: "Describe the activity",
  },
  {
    id: 6,
    img: activitySix,
    title: "activity",
    descriptionshort: "Describe the activity",
  },
  {
    id: 7,
    img: activitySeven,
    title: "activity",
    descriptionshort: "Describe the activity",
  },
  {
    id: 8,
    img: activityEight,
    title: "activity",
    descriptionshort: "Describe the activity",
  },
  {
    id: 9,
    img: activityNine,
    title: "activity",
    descriptionshort: "Describe the activity",
  },
  {
    id: 10,
    img: activityTen,
    title: "activity",
    descriptionshort: "Describe the activity",
  },
  {
    id: 11,
    img: activityEleven,
    title: "activity",
    descriptionshort: "Describe the activity",
  },
  {
    id: 12,
    img: activityTwelve,
    title: "activity",
    descriptionshort: "Describe the activity",
  },
  {
    id: 13,
    img: activityThirteen,
    title: "activity",
    descriptionshort: "Describe the activity",
  },
  {
    id: 14,
    img: activityFourteen,
    title: "activity",
    descriptionshort: "Describe the activity",
  },
  {
    id: 15,
    img: activityFifteen,
    title: "activity",
    descriptionshort: "Describe the activity",
  },
  {
    id: 16,
    img: activitySixteen,
    title: "activity",
    descriptionshort: "Describe the activity",
  }
];

const theme = createTheme();

const ActivitiesComponent = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 4,
            pb: 2,
          }}
        >
          <Container maxWidth="sm">          
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Activities
            </Typography>
            <Typography
              variant="body"
              align="center"
              color="text.secondary"
              paragraph
            >
              We organise activites for students and as well as for villagers.
            </Typography>
           
          </Container>
        </Box>
        <Container maxWidth="xl">
          {/* End hero unit */}
          <Grid container spacing={2}>
            {activities.map((activity) => (
              <Grid item key={activity.id} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={activity.img}
                    alt={activity.title}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    {/* <Typography gutterBottom variant="h5" component="h2">
                      {activity.title}
                    </Typography>
                    <Typography>{activity.descriptionshort}</Typography> */}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          {/* TODO : add buttons to navigate to activities page */}
        </Container>
      </main>
    </ThemeProvider>
  );
};

export default ActivitiesComponent;
