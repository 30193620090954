import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import ClarityComponent from './Clarity';

// const injectGA = () => {
//   if (typeof window == 'undefined') {
//     return;
//   }
//   window.dataLayer = window.dataLayer || [];
//   function gtag() {
//     window.dataLayer.push(arguments);
//   }
//   gtag('js', new Date());

//   gtag('config', 'G-WDL9LDTQBR');
// };

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* Global site tag (gtag.js) - Google Analytics */}
    {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-WDL9LDTQBR"></script>
    <script>{injectGA()}</script> */}
    
    <App />
    {/* <ClarityComponent projectId="he9vu81bpt" /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();