import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import imgSchoolOne from "../../images/school/or/schoolHomeImage.png";
import imgSchoolTwo from "../../images/school/op/schoolimage-2.png";
import imgSchoolThree from "../../images/school/op/schoolimage-3.png";
import imgSchoolFour from "../../images/school/op/schoolimage-4.png";
import imgSchoolFive from "../../images/school/op/schoolimage-5.png";
import imgSchoolSix from "../../images/school/op/schoolimage-6.png";
import imgSchoolSeven from "../../images/school/op/schoolimage-7.png";
import imgSchoolEight from "../../images/school/op/schoolimage-8.png";
import imgSchoolNine from "../../images/school/op/schoolimage-9.png";
import imgSchoolTen from "../../images/school/op/schoolimage-10.png";
import imgSchoolEleven from "../../images/school/op/schoolimage-11.png";
import imgSchoolTwelve from "../../images/school/op/schoolimage-12.png";
import imgSchoolThirteen from "../../images/school/op/schoolimage-13.png";
import imgSchoolFourteen from "../../images/school/op/schoolimage-14.png";
import imgSchoolFifteen from "../../images/school/op/schoolimage-15.png";
import imgSchoolSixteen from "../../images/school/op/schoolimage-16.png";
import imgSchoolSeventeen from "../../images/school/op/schoolimage-17.png";
import imgSchoolEighteen from "../../images/school/op/schoolimage-18.png";
import imgSchoolNineteen from "../../images/school/op/schoolimage-19.png";
import imgSchoolTwenty from "../../images/school/op/schoolimage-20.png";
import imgSchoolTwentlOne from "../../images/school/op/schoolimage-21.png";
import imgSchoolTwentyTwo from "../../images/school/op/schoolimage-22.png";

const schoolImages = [
  { id: 1, img: imgSchoolOne, name: "Primary School Kathwa", featured: true },
  { id: 2, img: imgSchoolTwo, name: "Primary School Kathwa" },
  { id: 3, img: imgSchoolThree, name: "Primary School Kathwa" },
  { id: 4, img: imgSchoolFour, name: "School" },
  { id: 5, img: imgSchoolFive, name: "Primary School Kathwa" },
  { id: 6, img: imgSchoolSix, name: "Primary School Kathwa" },
  { id: 7, img: imgSchoolSeven, name: "Primary School Kathwa" },
  { id: 8, img: imgSchoolEight, name: "Primary School Kathwa" },
  { id: 9, img: imgSchoolNine, name: "Primary School Kathwa" },
  { id: 10, img: imgSchoolTen, name: "Primary School Kathwa" },
  { id: 11, img: imgSchoolEleven, name: "Primary School Kathwa" },
  { id: 12, img: imgSchoolTwelve, name: "Primary School Kathwa" },
  { id: 13, img: imgSchoolThirteen, name: "Primary School Kathwa" },
  { id: 14, img: imgSchoolFourteen, name: "Primary School Kathwa" },
  { id: 15, img: imgSchoolFifteen, name: "Primary School Kathwa" },
  { id: 16, img: imgSchoolSixteen, name: "Primary School Kathwa" },
  { id: 17, img: imgSchoolSeventeen, name: "Primary School Kathwa" },
  { id: 18, img: imgSchoolEighteen, name: "Primary School Kathwa" },
  { id: 19, img: imgSchoolNineteen, name: "Primary School Kathwa" },
  { id: 20, img: imgSchoolTwenty, name: "Primary School Kathwa" },
  { id: 21, img: imgSchoolTwentlOne, name: "Primary School Kathwa" },
  { id: 22, img: imgSchoolTwentyTwo, name: "Primary School Kathwa" },
];

const theme = createTheme();

const AboutComponent = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <main>
        <Container sx={{ py: 4 }} maxWidth="md">
          <Typography
            component="h2"
            variant="h4"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Primary School Kathwa @ glance
            
          </Typography>
          </Container>

          <Container maxWidth="xl">
          <Grid container spacing={2}>
            {schoolImages.map((schoolimg) => (
              <Grid item key={schoolimg.id} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: "Auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={schoolimg.img}
                    alt={schoolimg.name}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
          </Container>
        
      </main>
    </ThemeProvider>
  );
};

export default AboutComponent;
